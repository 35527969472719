html {
  font-size: 10px;
  overflow-x: hidden;
}

/* adjust ant desing and fontawesome icons
*/
.ant-menu-item .ant-menu-item-icon,
.ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
  transform: translateY(-1px);
  -webkit-transform: translateY(-1px);
  -ms-transform: translateY(-1px);
}

/* adjust icons in ant button
*/
.ant-btn:not(.ant-btn-icon-only) svg,
.ant-btn:not(.ant-btn-icon-only) i {
  margin-right: 8px;
}

.ant-btn .ant-btn-loading-icon {
  transform: translateY(-3px);
  -webkit-transform: translateY(-3px);
  margin-right: 5px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 100;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 100;
  }

  100% {
    opacity: 0;
  }
}
